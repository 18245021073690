
import axios, { AxiosError } from "axios";
import { onMounted, ref, watch } from "vue";
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
// import Api from "../../services/Api";
// import s3 from '../../services/S3Service'
import useAlert from "@/composables/Alert";
import { useLoaderStore } from "@/store/LoaderStore"

export default defineComponent({
  name: "copyStores",
  props: {
    openClose: Boolean,
    inforStore: Object
  },
  setup(props, { emit }) {
    const dialogVisible = ref(false)
    const stores = ref<any>()
    const detailProduct = ref()
    const route = useRoute()
    const { id: id_store } = route.params
    const { showTimeAlert } = useAlert()
    const loaderStore = useLoaderStore()
    const router = useRouter()

    async function getStoresList() {
      try {
        const response = await axios.get('/api/getStore')
        stores.value = response.data.data
      } catch (error) {
        
      }
    }

    async function copyStoresList() {
      loaderStore.open()
      try {
        const response = await axios.post('/api/copyProductStore', {
          id_store_from: detailProduct.value,
          id_store_to: detailProduct.value
        })
        dialogVisible.value = false
        stores.value = response.data.data
        emit("update:ProductStoreList", true)
      } catch (error) {
        emit("update:ProductStoreList", false)
        if(error instanceof AxiosError) {
          showTimeAlert(error.message, 'error')
        }
      } finally {
        loaderStore.close()
      }
    }

    watch(() => props.openClose, () => {
      dialogVisible.value = !dialogVisible.value
    })

    onMounted(() => {
      getStoresList()
    })

    return {
      dialogVisible,
      stores,
      detailProduct,
      copyStoresList
    }
  },
});
