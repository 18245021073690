
import axios, { AxiosError } from 'axios';
import { defineComponent, ref, onMounted, watch, onBeforeMount } from 'vue';
import { useRoute } from "vue-router";
import { campHandleCNPJ } from '@/composables/DataValidation';
import useAlert from "@/composables/Alert";
import copyStores from "./Components/CopyByStore.vue"
import {
  CampHeader,
  CampEmptyListFeedback,
  CampTable,
  CampTableTd,
  CampTableTh,
  CampTableSortRowsByColumn
} from '@/components';
import { Modal } from "bootstrap";
import  UploadPlanilhas from "@/views/ProductStore/Components/UploadPlanilhas.vue"
import { useLoaderStore } from "@/store/LoaderStore"
import imgDefault from '@/assets/layout/default-noimgProduct.png'
import SkeletonComponent from '@/components/SkeletonComponent.vue';
import { IObjEmit, ISortComponentCore } from '@/components/Table/CampTableSortRowsByColumn.vue';
import { useFilterBaseV2Store } from '@/store/FilterBaseV2Store';

export interface IStoreData {
  id: number,
  cnpj: string,
  fantasy_name: string,
  responsible: string,
  email: string,
  telephone: string
}

export default defineComponent({
  name: "StoreList",
  components: {
    CampHeader,
    CampEmptyListFeedback,
    CampTable,
    CampTableTd,
    CampTableTh,
    CampTableSortRowsByColumn,
    UploadPlanilhas,
    copyStores,
    SkeletonComponent
  },
  setup() {
    const router = useRoute();
    const relationProps = ref(false)
    const relationData = ref(null)
    const productFilter = ref("")
    const dialogVisible = ref({
      xlms: false,
      copy: false
    })
    const ownRelationInfo = ref()
    const idStoreRef = ref<any>(0)
    const outerVisible = ref(false)
    const innerVisible = ref(false)
    const detailProduct = ref()
    const isLoadList = ref(false)
    const store = ref<IStoreData | null>(null)
    const sortComponentCore = ref<ISortComponentCore>({
      currentColumn: "",
      rows: {},
      chronology: []
    })
    const filterBaseV2Store = useFilterBaseV2Store()
    const auxCopyByStore = ref({ update: false })

    function openDragUpload() {
            dialogVisible.value.xlms = !dialogVisible.value.xlms
          }
    
    function openDragUploadCopy() {
      dialogVisible.value.copy = !dialogVisible.value.copy
    }
    
    const queryObject = ref<{
      isLoading: boolean,
      data:  null | any
    }>({
      isLoading: false,
      data: null
    })

    watch(() => productFilter.value, () => {
      relationData.value
    })

    function setDetailProduct(data) {
      detailProduct.value = data
      outerVisible.value = true
    }

    
    const loaderStore = useLoaderStore()

    let auxModal;

    const openModal = id => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
    };

    function funcDetailRelationDataProduct(data) {
      openModal('myModalProduct')
      relationProps.value = !relationProps.value
      relationData.value = data
    }

    async function getProductStore(storeId: number) {
      // loaderStore.open()
      try {
        isLoadList.value = true
        const storesPromise = axios.get(`/api/getStoreProduct/${storeId}`)
        const storePromise = axios.get(`/api/getStoreView/${storeId}`)
        const [responseStores, responseStore] = await Promise.all([storesPromise, storePromise])
        queryObject.value.data = responseStores.data.data
        sortComponentCore.value.rows = responseStores.data.data
        store.value = responseStore.data.data
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      } finally {
        isLoadList.value = false
        loaderStore.close()
      }
    }

    async function handleUpdateProduct() {
      loaderStore.open()
       
      const updateObject = {    
          sku: detailProduct.value.sku,
          id: detailProduct.value.id,
          id_store: detailProduct.value.id_store,
          description: detailProduct.value.description,
          name: detailProduct.value.name,
          points: detailProduct.value.points,
          order: detailProduct.value.order,
          internal_code: detailProduct.value.internal_code,
          category: detailProduct.value.category
      }
      try {
        const response = await axios.put('/api/putProductStore', updateObject)
        showTimeAlert('Produto atualizado com sucesso!')
        outerVisible.value = false
      } catch (error) {
        if(error instanceof AxiosError) {
            if(error.response) {
            showTimeAlert(error.response?.data.message, "error")
          }
        }
      } finally {
        loaderStore.close()
        innerVisible.value = false

      }
    }

    function handleColorStock(amount: number) {
      if(amount < 0)
        return ' #F64E60 !important'
      if(amount > 0)
        return ' #1DC9B7 !important'
      return ' #464E5F !important'
    }

    function sortRowsByColumn(obj: IObjEmit) {
      queryObject.value.data = obj.sortedList
      sortComponentCore.value = obj.sortComponentCore
    }

    const { showTimeAlert } = useAlert()
    const itemDel = ref<any>(null)
    const refModalDel = ref<HTMLElement | null>(null)
    function handleSetItemDel(item: IStoreData) {
      itemDel.value = item
    }
    function handleResetItemDel() {
      itemDel.value = null
    }
    async function handleConfirmItemDel() {
      if(itemDel.value) {
        try {
          const result = await axios.delete(`/api/deleteProductStore/${itemDel.value.sku}/${itemDel.value.id_store}`)
            showTimeAlert("Registro excluído com sucesso!")
            if(filterBaseV2Store.store?.id)
              getProductStore(filterBaseV2Store.store.id)
            handleResetItemDel()
        } catch (error) {
          if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else if (error instanceof Error) {
          showTimeAlert("Algo deu errado!", "error")
        }
      } finally {
        refModalDel.value?.click()
        }
      }
    }

    watch(() => filterBaseV2Store.checkForUpdates(), () => {
      if(filterBaseV2Store.checkForUpdates() && filterBaseV2Store.store?.id)
        getProductStore(filterBaseV2Store.store.id)
    })

    onMounted(() => {
      if(filterBaseV2Store.store?.id)
        getProductStore(filterBaseV2Store.store.id)
    })

    watch(() => auxCopyByStore.value.update, () => {
      if(auxCopyByStore.value.update && filterBaseV2Store.store?.id)
        getProductStore(filterBaseV2Store.store.id)
    })

    return {
      router,
      queryObject,
      handleSetItemDel,
      campHandleCNPJ,
      itemDel,
      handleConfirmItemDel,
      handleResetItemDel,
      refModalDel,
      relationData,
      relationProps,
      funcDetailRelationDataProduct,
      productFilter,
      openDragUpload,
      dialogVisible,
      ownRelationInfo,
      idStoreRef,
      outerVisible,
      innerVisible,
      detailProduct,
      setDetailProduct,
      imgDefault,
      openDragUploadCopy,
      handleUpdateProduct,
      isLoadList,
      handleColorStock,
      sortRowsByColumn,
      sortComponentCore,
      auxCopyByStore
    }
  }
})
